// // // for loacal run

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
const App = React.lazy(() => import('./App'));
// console.log = function(){}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={""}>
        <App />
    </Suspense>
  </React.StrictMode>
);
reportWebVitals();


// for widget
// import React, {Suspense} from 'react';
// import ReactDOM from 'react-dom';
// import './assets/scss/style.scss';
// const App = React.lazy(() => import('./App'));

// // Find all widget divs
// const WidgetDivs = document.querySelectorAll('.chat_talkk_widget')

// // Inject our React App into each
// WidgetDivs.forEach(Div => {
//   ReactDOM.render(
//       <Suspense fallback={""}>
//           <App domElement={Div} />
//       </Suspense>
//   );
// })
